import React, { useEffect, useRef, useState } from 'react'
import { ProfileDetails, fetchFarmerList, routeMaster } from '../../store/store'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap'
import SessionEndSelector from '../session/SessionEnd'
import { DatePicker, Icon } from '../../../../components'
import SessionStartSelector from '../session/SessionStart'
import { SearchDown } from '../dropDown/SearchDown'
import { extractDateFromTimestamp } from '../Date'
import { get, postToast } from '../../api/Api'

const VccAdvanceFilter = ({ vlccPurchase, getFilterValue, farmer }) => {
    const dispatch = useDispatch()
    const [rName, setRName] = useState(null)
    const codeFr = useRef(null)
    const [error, setError] = useState('');
    const searchDownRef = useRef();
    const [frName, setFrName] = useState(null)
    const farmerCode = useRef(null)
    const [centerList, setCenterList] = useState([])
    const [farmerData, setFarmerData] = useState([])
    const [centerId, setCenterId] = useState(null)

    const [details, setDetails] = useState({
        route_id: '',
        route_details: null,
        center_details: null,
        sessionDay: "M",
        sessionNight: "E",
        startDate: extractDateFromTimestamp(new Date()),
        endDate: extractDateFromTimestamp(new Date()),
        farmer_id: "",
        center_id: centerId,
        mode_payment: [],
        payment_cycle: [],
        entryMode: "DPU"
    })

    const validateFarmer = (value) => {
        if (!value || value.trim() === '') {
            // setNameError('Center Name is required.');
            return false;
        } else {
            // Add other validation checks as needed
            // setNameError('');
        }
    }

    useEffect(() => {
        dispatch(ProfileDetails())
    }, [dispatch])


    useEffect(() => {
        dispatch(fetchFarmerList())
    }, [dispatch])


    useEffect(() => {
        dispatch(routeMaster())
    }, [dispatch])

    const routeList = useSelector((state) => state.route)

    const handleName = (name) => {
        // setFrName(name)
    }

    useEffect(() => {
        dispatch(fetchFarmerList())
    }, [dispatch])

    const filtered_list = async (body) => {
        const url = `/api/direct-farmer/filtered_list`;
        try {
            const response = await postToast(url, body);
            if (response.status === true) {
                console.log("Server farmer response:", response.data.data);

                setCenterList(response.data.data)

                // setLoading(false);
            } else {
                // setLoading(false);
            }
        } catch (error) {
            console.error("Error while posting data:", error);
            // setLoading(false);
        }
    };


    useEffect(() => {

        const body = { type: ['1'] }
        if (body)
            filtered_list(body)

    }, []);

    const handleRoute = (value, id, code) => {
        // setFrName(`${code.code} - ${code.name}`)
        console.log(value, id, code, "data====>");
        console.log(id, "routeCode");
        // setCode(code.id)
        setDetails({ ...details, route_id: id, route_details: code })

        const fetchCenterList = async () => {
            const url = `/api/vcc-farmer/centersinroute/${id}`;

            try {
                const response = await get(url);
                if (response.status) {
                    const responseData = response.data
                    setCenterList(responseData ? responseData : [])
                }

            } catch (error) {
                console.error("Error while posting data:", error);
            }
        };

        if (id) {
            fetchCenterList()
        } else {
            const body = { type: ['1'] }
            if (body)
                filtered_list(body)
        }

    }




    const handleSessionStartChange = (value) => {
        console.log('Selected session start:', value);
        setDetails({ ...details, sessionDay: value });
    };

    const handleSessionEndChange = (value) => {

        setDetails({ ...details, sessionNight: value });
    };
    const handleFarmer = (value, id, code) => {
        // setFrName(`${code.code} - ${code.name}`)
        console.log(value, id, code, "data====>");
        console.log(id, "routeCode");
        // setFrCode(id)

        setDetails({ ...details, farmer_id: id })
        // setCode(code.id)
    }



    const handleCheck = () => {
        let isValid = true;

        if (details.endDate < details.startDate) {
            setError('End date must be equal to or higher than the start date');
            isValid = false;
        } else {
            setError('')
        }
        if (isValid) {
            getFilterValue(details)
        }

    };

    const validateDates = (end) => {
        setDetails({ ...details, endDate: end });
        if (end < details.startDate) {
            setError('End date must be equal to or higher than the start date');
        } else {
            setError('');
        }
    };
    const handleCenter = (value, id, code) => {

        setCenterId(id)
        setDetails({ ...details, center_id: id, center_details: code })

        const fetchFarmerData = async () => {
            const url = `/api/vcc-farmer/center/${id}`;
            try {
                const response = await get(url);
                if (response.status) {
                    const responseData = response.data
                    setFarmerData(responseData)
                }
            } catch (error) {
                console.error("Error while posting data:", error);
            }
        }
        if (id) {
            fetchFarmerData()
        }

    }

    const handleModeOfPayment = (value, isChecked, id) => {
        document.getElementById(id).blur();

        setDetails(prevFormData => {
            let updatedMode;

            if (isChecked) {
                // If the checkbox is checked, create a new array by spreading the existing values
                // and adding the new value to it
                updatedMode = [...prevFormData.mode_payment, parseFloat(value)];
                // setSelectMilkType(updatedMilkType)
            } else {
                // If the checkbox is unchecked, filter out the value from the array
                updatedMode = prevFormData.mode_payment.filter(item => item !== parseFloat(value));
                // setSelectMilkType(updatedMilkType)
            }

            return {
                ...prevFormData,
                mode_payment: updatedMode,
            };
        });

    }

    const handleCycle = (value, isChecked, id) => {
        document.getElementById(id).blur();

        setDetails(prevFormData => {
            let updatedCycle;

            if (isChecked) {
                // If the checkbox is checked, create a new array by spreading the existing values
                // and adding the new value to it
                updatedCycle = [...prevFormData.payment_cycle, value];
                // setSelectMilkType(updatedMilkType)
            } else {
                // If the checkbox is unchecked, filter out the value from the array
                updatedCycle = prevFormData.payment_cycle.filter(item => item !== value);
                // setSelectMilkType(updatedMilkType)
            }

            return {
                ...prevFormData,
                payment_cycle: updatedCycle,
            };
        });
    };



    return (
        <Card className="card-gutter-md mt-2 mb-2">
            <Card.Body>
                <Col xxl="12">
                    <Row className="g-gs">

                        {(!farmer && !vlccPurchase) && <Col lg="3">
                            <SearchDown
                                data={routeList.data}
                                placeholder="Route name"
                                name="Route name"
                                errors="Route name is required"
                                value={rName}
                                nextInputRef={codeFr}
                                onValidate={validateFarmer}
                                onSelect={(selectedItem, id, code) => {
                                    handleRoute(selectedItem, id, code)
                                }}
                                func={handleName}
                                star

                            />
                        </Col>}
                        {!vlccPurchase && <Col lg="3">
                            <SearchDown
                                star
                                ref={searchDownRef}
                                data={centerList}
                                placeholder="Center Name"
                                name="Center Name"
                                errors="Center Name is required"
                                value={frName}
                                nextInputRef={farmerCode}
                                onValidate={validateFarmer}
                                onSelect={(selectedItem, id, code) => {
                                    handleCenter(selectedItem, id, code)
                                }}
                                func={handleName}

                            />
                        </Col>}
                        {!vlccPurchase && <Col lg="3">
                            <SearchDown
                                star
                                ref={searchDownRef}
                                data={farmerData}
                                placeholder="Farmer Code"
                                name="Farmer Code"
                                errors="Farmer Code is required"
                                value={frName}
                                nextInputRef={farmerCode}
                                onValidate={validateFarmer}
                                onSelect={(selectedItem, id, code) => {
                                    handleFarmer(selectedItem, id, code)
                                }}
                                func={handleName}

                            />
                        </Col>}
                        {!farmer && <Col lg="7">
                            <Row className="g-gs">
                                <Col lg="3">
                                    <Form.Label htmlFor="start">Start date</Form.Label>
                                    <Form.Group className="form-group" controlId="validationCustom01">

                                        <div className="form-control-wrap">
                                            <DatePicker onDateChange={(value) =>
                                                setDetails({ ...details, startDate: value })} value={details.startDate} />
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col lg="3">
                                    <SessionStartSelector value={details.sessionDay} onChange={handleSessionStartChange} />
                                </Col>


                                <Col lg="3">
                                    <Form.Label htmlFor="start">End date</Form.Label>
                                    <Form.Group className="form-group" controlId="validationCustom01">

                                        <div className="form-control-wrap">
                                            <DatePicker onDateChange={(value) => validateDates(value)} value={details.endDate} />
                                        </div>
                                        {error && <div style={{ color: 'red' }}>{error}</div>}
                                    </Form.Group>
                                </Col>

                                <Col lg="3">
                                    <SessionEndSelector value={details.sessionNight} onChange={handleSessionEndChange} />
                                </Col>
                            </Row>
                        </Col>}



                        <Col lg="3">
                            <Row >
                                <Form.Group className="form-group">
                                    <Form.Label>
                                        Mode of Payment

                                    </Form.Label>

                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentMethodcash"
                                            id="cash"
                                            autoComplete="off"
                                            value={0}
                                            onChange={(e) => handleModeOfPayment(e.target.value, e.target.checked, e.target.id)}

                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="cash"
                                        >
                                            Cash
                                        </Button>

                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentMethodBank"
                                            id="bank"
                                            autoComplete="off"
                                            value={1}
                                            onChange={(e) => handleModeOfPayment(e.target.value, e.target.checked, e.target.id)}


                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="bank"
                                        >
                                            Bank
                                        </Button>

                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentMethod"
                                            id="upi"
                                            autoComplete="off"
                                            value={2}
                                            onChange={(e) => handleModeOfPayment(e.target.value, e.target.checked, e.target.id)}
                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="upi"
                                        >
                                            UPI
                                        </Button>
                                    </ButtonGroup>
                                </Form.Group>

                            </Row>
                        </Col>



                        {farmer && <Col md="3">
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="validationCustom03">
                                    Payment Cycle

                                </Form.Label>
                                <div className="form-control-wrap">
                                    <ButtonGroup aria-label="Basic radio toggle button group">
                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentCycleDaily"
                                            id="dailyRadio"
                                            value="daily"
                                            autoComplete="off"
                                            onChange={(e) => handleCycle(e.target.value, e.target.checked, e.target.id)}
                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="dailyRadio"
                                        >
                                            Daily
                                        </Button>

                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentCycleWeekly"
                                            id="weeklyRadio"
                                            value="weekly"
                                            autoComplete="off"
                                            onChange={(e) => handleCycle(e.target.value, e.target.checked, e.target.id)}

                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="weeklyRadio"
                                        >
                                            Weekly
                                        </Button>

                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            name="paymentCycleMonthly"
                                            id="monthlyRadio"
                                            value="monthly"
                                            autoComplete="off"
                                            onChange={(e) => handleCycle(e.target.value, e.target.checked, e.target.id)}

                                        />
                                        <Button
                                            as="label"
                                            variant="outline-primary"
                                            htmlFor="monthlyRadio"
                                        >
                                            Monthly
                                        </Button>
                                    </ButtonGroup>
                                </div>

                            </Form.Group>
                        </Col>}
                        <Col lg="2">

                            <Form.Group className="form-group">
                                <Form.Label htmlFor="end">Entry mode</Form.Label>
                                <div className="form-control-wrap">
                                    <ButtonGroup aria-label="Basic checkbox toggle button group">
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="entryMode"
                                            id="entryMode1"
                                            autoComplete="off"
                                            onChange={() => setDetails({ ...details, entryMode: "DPU" })}
                                            checked={details.entryMode === "DPU"}
                                        />
                                        <Button as="label" variant="outline-primary" htmlFor="entryMode1">
                                            <Icon name="tablet"></Icon>
                                        </Button>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="entryMode"
                                            id="entryMode2"
                                            autoComplete="off"
                                            checked={details.entryMode === "WEB"}
                                            onChange={() => setDetails({ ...details, entryMode: "WEB" })}
                                        />
                                        <Button as="label" variant="outline-primary" htmlFor="entryMode2">
                                            <Icon name="b-si"></Icon>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Form.Group>

                        </Col>


                        <Col lg="2">
                            <Col xxl="12">
                                <Row className="g-gs">

                                    <Col>
                                        <Button
                                            as="label"
                                            style={{ marginTop: "32px" }}
                                            variant="primary"
                                            onClick={handleCheck}
                                        >
                                            Get Data
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>

                        </Col>

                    </Row>


                </Col>
            </Card.Body >
        </Card >
    )
}

export default VccAdvanceFilter