import React, { useState } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';

const SocketFilter = ({ handleFilter }) => {
    const [filterData, setFilterData] = useState({
        days: "this_week",
        session: "",
        deviationDays: "",
        start_fat: "",
        end_fat: "",
        start_snf: "",
        end_snf: "",
        start_lit: "",
        end_lit: "",
        start_kg: "",
        end_kg: "",
        condition: "OR",
        filterType: "range",
        snf_percentage: "",
        fat_percentage: "",
        litre_percentage: "",
        kg_percentage: "",
        fat_condition: "OR",
        snf_condition: "OR",
        lit_condition: "OR",
        kg_condition: "OR",
    });

    const handleInputChange = (e) => {
        setFilterData({ ...filterData, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        handleFilter(filterData);
    };

    return (
        <Form className="bg-light p-2 rounded">
            <Row className="align-items-end g-1 mb-2">
                <Col xs={12} sm={6} md={3} lg={2}>
                    <Form.Select
                        name="days"
                        value={filterData.days}
                        onChange={handleInputChange}

                    >
                        <option value="this_week">This Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="this_month">This Month</option>
                        <option value="last_month">Last Month</option>
                    </Form.Select>
                </Col>
                <Col xs={6} sm={3} md={2} lg={1}>
                    <Form.Select
                        name="session"
                        value={filterData.session}
                        onChange={handleInputChange}

                    >
                        <option value="">All</option>
                        <option value="M">M</option>
                        <option value="E">E</option>
                    </Form.Select>
                </Col>
                <Col xs={6} sm={3} md={2} lg={2}>
                    <Form.Select
                        name="deviationDays"
                        value={filterData.deviationDays}
                        onChange={handleInputChange}

                    >
                        <option value="">Deviation Days</option>
                        <option value="1">1 day</option>
                        <option value="2">2 days</option>
                        <option value="3">3 days</option>
                        <option value="5">5 days</option>
                        <option value="7">7 days</option>
                    </Form.Select>
                </Col>
                <Col xs={12} sm={6} md={4} lg={3}>
                    <Form.Group>
                        <Form.Label className="mb-0">Filter Type</Form.Label>
                        <div>
                            <Form.Check
                                inline
                                type="radio"
                                label="Range"
                                name="filterType"
                                value="range"
                                checked={filterData.filterType === "range"}
                                onChange={handleInputChange}
                            />
                            <Form.Check
                                inline
                                type="radio"
                                label="Percentage"
                                name="filterType"
                                value="percentage"
                                checked={filterData.filterType === "percentage"}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Form.Group>
                </Col>
            </Row>

            {filterData.filterType === "range" ? (
                <Row className="align-items-end g-1">
                    <Col xs={6} sm={3} md={2} lg={1}>
                        <Form.Control
                            placeholder="S.FAT"
                            name="start_fat"
                            value={filterData.start_fat}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={6} sm={3} md={2} lg={1}>
                        <Form.Control

                            placeholder="E.FAT"
                            name="end_fat"
                            value={filterData.end_fat}
                            onChange={handleInputChange}
                        />
                    </Col>

                    <Col xs={6} sm={2} lg={1}>
                        <InputGroup >
                            <Button
                                size="sm"
                                variant={filterData.fat_condition === 'OR' ? 'primary' : 'outline-primary'}
                                onClick={() => setFilterData({ ...filterData, fat_condition: 'OR' })}
                            >
                                OR
                            </Button>
                            <Button
                                size="sm"
                                variant={filterData.fat_condition === 'AND' ? 'primary' : 'outline-primary'}
                                onClick={() => setFilterData({ ...filterData, fat_condition: 'AND' })}
                            >
                                AND
                            </Button>
                        </InputGroup>
                    </Col>

                    <Col xs={6} sm={3} md={2} lg={1}>
                        <Form.Control

                            placeholder="S.SNF"
                            name="start_snf"
                            value={filterData.start_snf}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={6} sm={3} md={2} lg={1}>
                        <Form.Control

                            placeholder="E.SNF"
                            name="end_snf"
                            value={filterData.end_snf}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={6} sm={2} lg={1}>
                        <InputGroup >
                            <Button
                                size="sm"
                                variant={filterData.snf_condition === 'OR' ? 'primary' : 'outline-primary'}
                                onClick={() => setFilterData({ ...filterData, snf_condition: 'OR' })}
                            >
                                OR
                            </Button>
                            <Button
                                size="sm"
                                variant={filterData.snf_condition === 'AND' ? 'primary' : 'outline-primary'}
                                onClick={() => setFilterData({ ...filterData, snf_condition: 'AND' })}
                            >
                                AND
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col xs={6} sm={3} md={2} lg={1}>
                        <Form.Control

                            placeholder="S.LIT"
                            name="start_lit"
                            value={filterData.start_lit}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={6} sm={3} md={2} lg={1}>
                        <Form.Control

                            placeholder="E.LIT"
                            name="end_lit"
                            value={filterData.end_lit}
                            onChange={handleInputChange}
                        />
                    </Col>



                </Row>
            ) : (
                <Row className="align-items-end g-1">
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Control

                            placeholder="SNF %"
                            name="snf_percentage"
                            value={filterData.snf_percentage}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Control

                            placeholder="FAT %"
                            name="fat_percentage"
                            value={filterData.fat_percentage}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Control

                            placeholder="Litre %"
                            name="litre_percentage"
                            value={filterData.litre_percentage}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={6} sm={3} md={3} lg={3}>
                        <Form.Control

                            placeholder="KG %"
                            name="kg_percentage"
                            value={filterData.kg_percentage}
                            onChange={handleInputChange}
                        />
                    </Col>

                </Row>
            )}

            <Row className="mt-2">

                <Col xs={12} sm={6} md={4} lg={2}>
                    <Button onClick={handleSubmit} variant="primary" className="w-100">Get Data</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default SocketFilter;